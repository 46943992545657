@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/static/pretendard.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body,
html {
  font-family: 'Pretendard';
  font-weight: 400 default;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: #333;
}

#__next {
  position: relative;
  width: 100%;
  height: 100%;
}

*,
:after,
:before {
  box-sizing: border-box;
  font-family: 'Pretendard';
  word-wrap: break-word;
}


*:focus-visible {
  outline: none;
}

a {
    text-decoration: none;
    color: #333;
}

a:hover,
a:visited {
  text-decoration: none;
  color: #333;
}

strong {
  font-weight: 700;
}